import React, { useState } from 'react';
import { BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  ShowButton,
  DateField,
  BooleanField,
} from '@refinedev/antd';
import { Table, Space, Button, Form, Select, Tag } from 'antd';
import { OpenAIOutlined, SyncOutlined } from '@ant-design/icons';
import { downloadBHFExcel } from '../../Actions/ConfigsActions';

export const DataDrivenFormList = () => {
  const [downloadExcelLoading, setDownloadExcelLoading] =
    useState<boolean>(false);

  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
    setFilters,
  } = useTable({
    resource: 'user_submission',
    syncWithLocation: true,
    filters: {
      initial: [
        {
          field: 'form_type',
          operator: 'eq',
          value: 'DATA_DRIVEN',
        },
      ],
    },
    //@ts-ignore
    onSearch: (values: { form_status: string }) => {
      let filters = [
        {
          field: 'form_status',
          operator: 'eq',
          value: values.form_status,
        },
      ];
      return filters;
    },
  });
  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      return currentFilters.filter(
        (filter: { field: string }) => filter.field === 'form_type'
      );
    }, 'replace');
    refetch();
  };
  const updateLoading = (value: boolean) => {
    setDownloadExcelLoading(value);
  };
  const onExport = async () => {
    await downloadBHFExcel(updateLoading);
  };
  return (
    <List>
      <div className="flex justify-between items-center mb-2">
        <Form
          layout="inline"
          {...searchFormProps}
          onValuesChange={() => {
            searchFormProps.form?.submit();
          }}
          className="mb-2"
        >
          <Form.Item name={'form_status'}>
            <Select
              placeholder="Form Status"
              options={[
                { value: 'in_progress', label: 'In Progress' },
                { value: 'submitted', label: 'Completed' },
              ]}
            />
          </Form.Item>

          <Button onClick={() => handleReset()} type="primary">
            <SyncOutlined />
            <div className="text-white">Reset Filters</div>
          </Button>
        </Form>
        <Button
          loading={downloadExcelLoading}
          onClick={onExport}
          type="primary"
          className="mb-4"
        >
          Export
        </Button>
      </div>
      <Table
        dataSource={tableProps.dataSource}
        loading={tableProps.loading}
        rowKey="id"
      >
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={['user', 'company_name']}
          title="Company Name"
        />
        <Table.Column
          dataIndex="form_status"
          title="Select Form Status"
          render={(value: string) => (
            <Tag color={value === 'in_progress' ? 'blue' : 'green'}>
              {value === 'in_progress' ? 'In Progress' : 'Completed'}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex={['created_at']}
          title="Creation Date"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['updated_at']}
          title="Last Updated"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['is_deleted']}
          title="Deleted"
          render={(value: any) => <BooleanField value={value} />}
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {record.form_status === 'submitted' && (
                <ShowButton
                  icon={<OpenAIOutlined />}
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              )}
              {/* <Button
                icon={<UploadOutlined />}
                onClick={() => importDoctorstModal(true, record.id)}
                size="small"
              /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
