import React, { useCallback, useEffect, useState } from 'react';
import { BaseKey, IResourceComponentsProps, useParsed } from '@refinedev/core';
import { List } from '@refinedev/antd';
import {
  Collapse,
  Table,
  message,
  Skeleton,
  Button,
  Tooltip,
  Form,
  Select,
} from 'antd';
import type { CollapseProps } from 'antd';
import { apiRequest } from '../../rest-data-provider/utils/APIs';
import {
  AiInteractResponse,
  AiMessage,
  DDFRes,
  DynamicQuestion,
} from '../../types';
import { DynamicQuestions } from '@components/DynamicQuestions';
import { SubItemsCollapse } from '@components/SubItemsCollapse';
import Chatbox from '@components/ChatBox';
import {
  CaretRightOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { handleSendReport } from '../../Actions/ConfigsActions';
import ImporReportModal from '@components/UploadReportModal';

export const DataDrivenFormShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submmittedFormId, setSubmmittedFormId] = useState<number>();
  const importDoctorstModal = (value: boolean, id?: BaseKey) => {
    if (value === true) {
      setSubmmittedFormId(id as number);
    }
    setIsModalVisible(value);
  };
  const [inputValue, setInputValue] = useState('');
  const [dataDrivenFormSubmission, setDataDrivenFormSubmission] =
    useState<DDFRes[]>();
  const [loading, setLoading] = useState(true);
  const [chatbotMessages, setChatbotMessages] = useState<AiMessage[]>([]);
  const [aiLoading, setAiLoading] = useState(false);
  const [confirmAiLoading, setConfirmAiLoading] = useState(false);
  const [answerId, setAnswerId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [allAiAnswersGenerated, setAllAiAnswersGenerated] = useState(true);
  const [filterOption, setFilterOption] = useState('all');
  const fetchDataDrivenFormSubmission = useCallback(async () => {
    try {
      const { data } = await apiRequest<DDFRes[]>({
        url: `user_submission/dataDrivenFormSubmission/${id}`,
        method: 'GET',
        params: {
          ai_answer_status: filterOption,
        },
      });
      setDataDrivenFormSubmission(data);
      setLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  }, [id, filterOption]);
  const handleCheckAllSubmited = useCallback(async () => {
    try {
      const { data } = await apiRequest<boolean>({
        url: `user_submission/ai-status/${id}`,
      });
      setAllAiAnswersGenerated(data);
    } catch (error) {
      message.error('Something went wrong');
    }
  }, [id]);
  useEffect(() => {
    handleCheckAllSubmited();
  }, [id, handleCheckAllSubmited]);

  useEffect(() => {
    fetchDataDrivenFormSubmission();
  }, [id, fetchDataDrivenFormSubmission]);

  useEffect(() => {
    fetchDataDrivenFormSubmission();
  }, [filterOption, fetchDataDrivenFormSubmission]);

  const handleQuestionClick = (question: DynamicQuestion) => {
    setIsOpen(true);
    const questionText = question.question_text
      ? `${question.question_text} ${question.question_text === 'SWOT analysis' ? question.question_item : ''}`
      : question.row_name !== 'null'
        ? `${question.row_name} (${question.column_name})`
        : question.metric
          ? `Metric: ${question.metric} Description: ${question.metric_description}`
          : null;
    const answerText = question.answer[0]?.answer_text || 'No answer provided';
    setInputValue(`Question: ${questionText} Answer: ${answerText}`);
    setAnswerId(question.answer[0].id);
  };
  const PillarQuestion = ({ item }: { item: DDFRes }) => {
    const items: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Pillar Questions',
        children: (
          <DynamicQuestions
            onQuestionClick={handleQuestionClick}
            questions={item.dynamic_questions || []}
          />
        ),
      },
    ];
    return <Collapse items={items} />;
  };

  const items: CollapseProps['items'] = dataDrivenFormSubmission?.map(
    (item) => ({
      key: item.id,
      label: item.name,
      children: (
        <div className="flex flex-col gap-y-1">
          {item.dynamic_questions.length > 0 && <PillarQuestion item={item} />}
          <SubItemsCollapse
            onQuestionClick={handleQuestionClick}
            subItems={item.area || []}
          />
        </div>
      ),
      style: { borderColor: '#f37421' },
    })
  );

  const columns = [
    {
      title: 'Pillar',
      dataIndex: 'content',
      key: 'content',
    },
  ];

  const data = [
    {
      key: '1',
      content: (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          items={items}
          className="bg-white border-primary"
        />
      ),
    },
  ];
  const handleSubmit = async () => {
    if (answerId !== undefined) {
      setAiLoading(true);
      setInputValue('');
      setChatbotMessages((prev) => [
        ...prev,
        {
          sender: 'User',
          message: inputValue,
          answer_id: answerId,
        },
      ]);
      try {
        const { data } = await apiRequest<AiInteractResponse>({
          url: 'user_submission/interactWithAI',
          method: 'POST',
          data: {
            ai_content: inputValue,
            user_submission_id: parseInt(id as string, 10),
          },
        });
        setChatbotMessages((prev) => [
          ...prev,
          {
            sender: 'Bot',
            message: data.message.content,
            answer_id: answerId,
          },
        ]);
      } catch (error) {
        message.error('Something went wrong');
      } finally {
        setAiLoading(false);
      }
    } else {
      message.error('No answer provided');
    }
  };
  const handleConfirmAnswer = async (answerText: string, answer_id: number) => {
    setConfirmAiLoading(true);
    try {
      await apiRequest({
        url: `/user_submission/confirmAiAnswer`,
        data: {
          answer_id: answer_id,
          answer_text: answerText,
        },
        method: 'POST',
      });
      message.success('Answer submitted successfully');
      handleCheckAllSubmited();
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setConfirmAiLoading(false);
    }
  };
  const handleFilterChange = (value: string) => {
    setFilterOption(value);
  };
  const handleReset = () => {
    setFilterOption('all');
    fetchDataDrivenFormSubmission();
  };
  return (
    <>
      <List
        title="Data Driven Form"
        headerButtons={
          <div className="flex gap-x-2">
            <Tooltip title={'Generate Report'}>
              <Button
                onClick={() => handleSendReport(id as number)}
                disabled={!allAiAnswersGenerated}
                type="primary"
              >
                Generate Report
              </Button>
            </Tooltip>
            <Tooltip title={'Upload the report to send to the user'}>
              <Button
                icon={<UploadOutlined />}
                onClick={() => importDoctorstModal(true, id)}
                type="primary"
                disabled={!allAiAnswersGenerated}
              >
                Upload Report
              </Button>
            </Tooltip>
          </div>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="flex justify-between items-center mb-2">
              <Form layout="inline" className="mb-2">
                <Form.Item name={'generate_status'}>
                  <Select
                    dropdownStyle={{ minWidth: 200 }}
                    placeholder="Select Filter"
                    className="min-w-52"
                    value={filterOption}
                    onChange={(value) => handleFilterChange(value)}
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'answered', label: 'Fed to AI' },
                      { value: 'un_answered', label: 'Not Fed to AI' },
                    ]}
                  />
                </Form.Item>
                <Button type="primary" onClick={handleReset}>
                  <SyncOutlined />
                  <div className="text-white">Reset</div>
                </Button>
              </Form>
            </div>
            <Table
              pagination={false}
              showHeader={false}
              columns={columns}
              rowKey="key"
              dataSource={data}
            />
          </>
        )}
        <div className="fixed bottom-0 right-8 bg-white border-2 p-2 rounded-t-lg  w-1/3">
          <Chatbox
            chatbotMessages={chatbotMessages}
            handleSubmit={handleSubmit}
            inputValue={inputValue}
            setInputValue={setInputValue}
            aiLoading={aiLoading}
            handleConfirmAnswer={handleConfirmAnswer}
            confirmAiLoading={confirmAiLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      </List>
      {submmittedFormId && (
        <ImporReportModal
          open={isModalVisible}
          updateModalOpen={importDoctorstModal}
          submmittedFormId={submmittedFormId}
        />
      )}
    </>
  );
};
