import { message } from 'antd';
import {
  apiRequest,
  apiRequestGet,
  apiRequestGetFile,
  apiRequestPost,
} from '../rest-data-provider/utils/APIs';
import { AccountStore } from '../Store';

export const CreateRecordAction = async (
  url: string,
  data: any,
  navigateToEdit?: (url: string, id?: string) => void,
  goBack?: () => void,
  refetch?: () => void,
  resetForm?: () => void
) => {
  try {
    const { data: res } = await apiRequestPost<{ id: any }>({
      url,
      data,
      headers: {
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.signedAccessToken
        }`,
      },
    });
    if (res) {
      resetForm && resetForm();
      goBack && goBack();
      navigateToEdit && navigateToEdit(url, res?.id);
      refetch && refetch();

      message.success('Record created successfully');
    }
  } catch (error: any) {
    message.error('Error in creating record');
  }
};

export const GetOneRecordAction = async (type: string, id: string) => {
  try {
    await apiRequestGet<boolean>({
      url: `/${type}/${id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.signedAccessToken
        }`,
      },
    });
  } catch (error: any) {
    message.error('Error in getting record');
  }
};

export const handleSendReport = async (submission: number) => {
  try {
    apiRequest({
      url: `user_submission/generateExcelSheet/${submission}`,
      method: 'GET',
    });
    message.success('Report generated successfully');
  } catch (error) {
    message.error('Error in generating report');
  }
};

const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const downloadBhfReport = async (
  id: string,
  updateDownloadLoading: (val: boolean) => void
) => {
  updateDownloadLoading(true);
  try {
    const response = await apiRequestGetFile<Blob>({
      url: `/business-health-form/pdf/${id}`,
    });
    if (response.data instanceof Blob) {
      downloadFile(response.data, `BusinessHealthForm_${id}.pdf`);
      message.success('Report Downloaded Successfully');
    } else {
      throw new Error('Invalid response type');
    }
  } catch (error) {
    message.error('Error in Downloading Report');
  } finally {
    updateDownloadLoading(false);
  }
};
export const downloadOnBoardingExcel = async (
  updateDownloadLoading: (val: boolean) => void
) => {
  updateDownloadLoading(true);
  try {
    const response = await apiRequestGetFile<Blob>({
      url: '/user_submission/on-boarding-submissions-to-excel-sheet',
    });
    if (response.data instanceof Blob) {
      downloadFile(response.data, 'OnBoardingForm.xlsx');
      message.success('Excel Downloaded Successfully');
    } else {
      throw new Error('Invalid response type');
    }
  } catch (error) {
    message.error('Error in Downloading Excel Report');
  } finally {
    updateDownloadLoading(false);
  }
};
export const downloadBHFExcel = async (
  updateDownloadLoading: (val: boolean) => void
) => {
  updateDownloadLoading(true);
  try {
    const response = await apiRequestGetFile<Blob>({
      url: '/user_submission/user-info-to-excel-sheet',
    });
    if (response.data instanceof Blob) {
      downloadFile(response.data, 'BusinessHealthAndDataDrivenForm.xlsx');
      message.success('Excel Downloaded Successfully');
    } else {
      throw new Error('Invalid response type');
    }
  } catch (error) {
    message.error('Error in Downloading Excel Report');
  } finally {
    updateDownloadLoading(false);
  }
};
