import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord, BaseKey } from '@refinedev/core';
import {
  List,
  ShowButton,
  DateField,
  BooleanField,
  TextField,
  useTable,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Select,
  Tag,
  Form,
  Button,
  Tooltip,
  message,
} from 'antd';
import {
  DownloadOutlined,
  OpenAIOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import './../style.css';
import { useNavigate } from 'react-router-dom';
import BHAIModal from '../../components/BHFAI';
import { BHFRes } from '../../types';
import {
  downloadBHFExcel,
  downloadBhfReport,
} from '../../Actions/ConfigsActions';
export const BusinessHealthFormList: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();
  const [aiModalVisible, setAiModalVisible] = useState(false);
  const [modalId, setModalId] = useState<number>();
  const [submmittedFormId, setSubmmittedFormId] = useState<number>();
  const [downloadBhfReportLoading, setDownloadBhfReportLoading] =
    useState(false);
  const [downloadExcelLoading, setDownloadExcelLoading] =
    useState<boolean>(false);

  const handleModalVisible = (value: boolean) => {
    setAiModalVisible(value);
  };
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
    setFilters,
  } = useTable({
    syncWithLocation: true,
    resource: 'user_submission',
    filters: {
      initial: [
        { field: 'form_type', operator: 'eq', value: 'BUSINESS_HEALTH' },
      ],
    },
    //@ts-ignore
    onSearch: (values: {
      form_status: string;
      is_pay_for_business_health_form: boolean;
      is_ai_answers_generated: boolean;
    }) => {
      let filters = [
        {
          field: 'form_status',
          operator: 'eq',
          value: values.form_status,
        },
        {
          field: 'is_pay_for_business_health_form',
          operator: 'eq',
          value: values.is_pay_for_business_health_form,
        },
        {
          field: 'is_ai_answers_generated',
          operator: 'eq',
          value: values.is_ai_answers_generated,
        },
      ];
      return filters;
    },
  });
  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      return currentFilters.filter(
        (filter: { field: string }) =>
          filter.field !== 'is_pay_for_business_health_form' &&
          filter.field !== 'form_status' &&
          filter.field !== 'is_ai_answers_generated'
      );
    }, 'replace');

    refetch();
  };
  const handleShowModal = (
    user_Id: BaseKey | undefined,
    form_id: BaseKey | undefined
  ) => {
    if (user_Id !== undefined && form_id !== undefined) {
      const numericId =
        typeof user_Id === 'string' ? parseInt(user_Id, 10) : user_Id;
      const numericId2 =
        typeof form_id === 'string' ? parseInt(form_id, 10) : form_id;
      setModalId(numericId);
      setSubmmittedFormId(numericId2);
      setAiModalVisible(true);
    }
  };
  const updateDownloadLoading = (value: boolean) => {
    setDownloadBhfReportLoading(value);
  };
  const handleReportDownload = async (id: BaseKey | undefined) => {
    if (!id) {
      message.error('Invalid report ID');
      return;
    }
    await downloadBhfReport(id as string, updateDownloadLoading);
  };
  const updateLoading = (value: boolean) => {
    setDownloadExcelLoading(value);
  };
  const onExport = async () => {
    await downloadBHFExcel(updateLoading);
  };
  return (
    <>
      <List>
        <div className="flex justify-between items-center mb-2">
          <Form
            layout="inline"
            {...searchFormProps}
            onValuesChange={() => {
              searchFormProps.form?.submit();
            }}
            className="mb-2"
          >
            <Form.Item name={'form_status'}>
              <Select placeholder="Form Status">
                <Select.Option value="in_progress">In Progress</Select.Option>
                <Select.Option value="submitted">Completed</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={'is_pay_for_business_health_form'}>
              <Select placeholder="Payment Status">
                <Select.Option value={true}>Paid</Select.Option>
                <Select.Option value={false}>Not Paid</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={'is_ai_answers_generated'}>
              <Select placeholder="Report Generated">
                <Select.Option value={true}>Generated</Select.Option>
                <Select.Option value={false}>Not Generated</Select.Option>
              </Select>
            </Form.Item>
            <Button onClick={() => handleReset()} type="primary">
              <SyncOutlined />
              <div className="text-white">Reset Filters</div>
            </Button>
          </Form>
          <div className="flex flex-row justify-center items-center gap-x-2">
            <Button
              type="primary"
              onClick={() => navigate('/edit-business-health-report')}
              className="mb-4"
            >
              Edit Report
            </Button>
            <Button
              loading={downloadExcelLoading}
              onClick={onExport}
              type="primary"
              className="mb-4"
            >
              Export
            </Button>
          </div>
        </div>
        <Table
          dataSource={tableProps?.dataSource}
          loading={tableProps?.loading}
          rowKey={'id'}
        >
          <Table.Column dataIndex="id" title="Record ID" />
          <Table.Column
            dataIndex={['user', 'company_name']}
            title="Company Name"
            render={(value: string) => <TextField value={value || '-'} />}
          />
          <Table.Column
            dataIndex={['user', 'city']}
            title="City"
            render={(value: string) => <TextField value={value || '-'} />}
          />
          {/* <Table.Column
          dataIndex={['user', 'account']}
          title="User Email"
          render={(value: string) => <TextField value={value || '-'} />}
        /> */}
          <Table.Column
            dataIndex={['user', 'business_type']}
            title="Business Type"
          />
          <Table.Column
            dataIndex="form_status"
            title="Form Status"
            render={(value: string) => (
              <Tag color={value === 'in_progress' ? 'blue' : 'green'}>
                {value === 'in_progress' ? 'IN PROGRESS' : 'COMPLETED'}
              </Tag>
            )}
          />
          <Table.Column
            dataIndex={['created_at']}
            title="Creation Date"
            render={(value: Date) => <DateField value={value} />}
          />
          <Table.Column
            dataIndex={['updated_at']}
            title="Last Updated"
            render={(value: Date) => <DateField value={value} />}
          />
          <Table.Column
            dataIndex={['is_deleted']}
            title="Deleted"
            render={(value: boolean) => <BooleanField value={value} />}
          />

          <Table.Column<BHFRes>
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                {record.form_status === 'submitted' &&
                  record.ai_video_link == null && (
                    <Tooltip title="Generate AI Content" placement="top">
                      <Button
                        onClick={() =>
                          handleShowModal(record.user_id, record.id)
                        }
                        icon={<OpenAIOutlined />}
                        size="small"
                      />
                    </Tooltip>
                  )}
                {record.ai_video_link && (
                  <Tooltip title="Download Report" placement="top">
                    <Button
                      onClick={() => handleReportDownload(record.id)}
                      icon={<DownloadOutlined />}
                      size="small"
                      loading={downloadBhfReportLoading}
                    />
                  </Tooltip>
                )}
              </Space>
            )}
          />
        </Table>
      </List>
      {modalId !== undefined && submmittedFormId !== undefined && (
        <BHAIModal
          open={aiModalVisible}
          updateModalOpen={handleModalVisible}
          refetch={refetch}
          id={modalId}
          formId={submmittedFormId}
        />
      )}
    </>
  );
};
